import { apiToastError } from '@/@core/utils/toast'
import { useRouter } from '@/@core/utils/utils'
import store from '@/store'
import {
  FETCH_AVAILABLE_CREDITS,
  GET_SAVED_LEADS,
  POLL_REQUESTED_CONTACTS,
  SET_AVAILABLE_CREDITS,
  SET_SAVED_LEADS_CONTACTS,
  UPDATE_LEAD_CONTACTS,
} from '@/store/modules/leadai.module'
import { computed, ref, watch } from '@vue/composition-api'
import { ContactStatus } from '../../leadsAiConstants'

export const useSavedLeads = () => {
  const savedLeads = ref([])
  const totalSavedLeads = ref(0)
  const pageSize = 20
  const isFetchingData = ref(true)
  const { router, route } = useRouter()
  const savedLeadContacts = computed(() => store.state.leadai.savedLeadsContacts)
  const currentPage = ref(route?.value.query?.pageNumber ? Number(route.value.query?.pageNumber) : 1)
  const leadToHighlight = ref(route?.value.query?.savedLeadId ?? '')
  let contactTimeoutId

  watch(savedLeadContacts, () => {
    pollContactData()
  })

  watch(currentPage, () => {
    pollContactData()
  })

  const processContactResponse = contacts => contacts.reduce((acc, contact) => {
    if (!acc[contact.leadId]) {
      acc[contact.leadId] = []
    }
    acc[contact.leadId].push(contact)
    return acc
  }, {})

  const updateLeadContacts = (leadId, existingContacts, newContacts) => {
    const updatedContacts = existingContacts.map(contact => {
      const newContact = newContacts.find(newContact => newContact._id === contact.id)
      if (!newContact) return contact

      return {
        leadName: newContact.name,
        jobTitle: newContact.jobTitle,
        email: newContact.email,
        phoneNumber: newContact.phone,
        id: newContact._id,
      }
    })

    store.commit(UPDATE_LEAD_CONTACTS, {
      leadId,
      updatedContacts,
    })
  }

  const checkForRefunds = async contacts => {
    const isResultNull = contacts.some(contact => !contact.phone || !contact.email)
    if (!isResultNull) return
    store.dispatch(FETCH_AVAILABLE_CREDITS, {
      brandId: store.getters.currentEntityId,
    }).then(availableCreditsResponse => {
      if (availableCreditsResponse?.data) {
        store.commit(SET_AVAILABLE_CREDITS, availableCreditsResponse.data?.availableCredits)
      }
    })
  }

  const fetchAndSetContacts = async contactsToFetch => {
    try {
      const allRequestedContactIds = contactsToFetch.flatMap(
        contact => contact.requestedContactIds,
      )

      const response = await store.dispatch(POLL_REQUESTED_CONTACTS, {
        data: allRequestedContactIds,
      })

      if (!response.data?.contacts) return

      checkForRefunds(response.data.contacts)
      const leadWiseContacts = processContactResponse(response.data.contacts)

      Object.entries(leadWiseContacts).forEach(([leadId, contactsFromApi]) => {
        const existingContacts = store.state.leadai.savedLeadsContacts
          .find(data => data.leadId === leadId)?.contacts || []

        updateLeadContacts(leadId, existingContacts, contactsFromApi)
      })
    } catch (err) {
      console.error('Error fetching contacts:', err)
    }
  }

  const pollContactData = () => {
    clearInterval(contactTimeoutId)
    contactTimeoutId = setInterval(() => {
      const contacts = store.state.leadai.savedLeadsContacts
      const contactsToFetch = []
      contacts.forEach(contact => {
        const contactsRequested = contact.contacts.filter(contact => contact.email === ContactStatus.EXTERNAL_REVEAL_REQUIRED || contact.email === ContactStatus.REVEAL_REQUESTED || contact.phoneNumber === ContactStatus.EXTERNAL_REVEAL_REQUIRED || contact.phoneNumber === ContactStatus.REVEAL_REQUESTED)
        if (contactsRequested.length) {
          contactsToFetch.push({
            leadId: contact.leadId,
            requestedContactIds: contactsRequested.reduce((acc, curr) => [...acc, curr.id], []),
          })
        }
      })

      if (contactsToFetch.length === 0) {
        clearInterval(contactTimeoutId)
      } else {
        fetchAndSetContacts(contactsToFetch)
      }
    }, 10000)
  }

  const getSavedLeads = async () => {
    try {
      const response = await store.dispatch(GET_SAVED_LEADS, {
        pageNumber: currentPage.value,
        pageSize,
      })
      savedLeads.value = response.data.data
      totalSavedLeads.value = response.data.totalCount
      store.commit(SET_SAVED_LEADS_CONTACTS, savedLeads.value.map(val => ({
        leadId: val.id,
        contacts: val.contacts,
      })))

      pollContactData()
    } catch (err) {
      apiToastError(err)
    } finally {
      isFetchingData.value = false
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  const fetchLeadDetails = leadId => {
    router.push({ name: 'leads-gen-ai-detail', params: { leadId } })
  }

  const handlePageChange = async pageNumber => {
    currentPage.value = pageNumber
    await getSavedLeads()
  }

  return {
    savedLeads,
    getSavedLeads,
    totalSavedLeads,
    fetchLeadDetails,
    isFetchingData,
    currentPage,
    handlePageChange,
    pageSize,
    leadToHighlight,
  }
}
