<template>
  <b-modal
    id="get-credits-modal"
    centered
    hide-footer
    hide-header-close
    @hide="handleHide"
  >
    <div class="modal-wrapper">
      <button
        class="close-btn"
        @click="$bvModal.hide('get-credits-modal')"
      >
        <close-icon />
      </button>
      <p class="pink-text">
        <span v-if="isSuccess">Thank you! <br> We will be in touch shortly.</span>
        <span v-else-if="isRequestSaved">Your request for credits has already been received, Thank you! We will be in touch shortly.</span>
        <span v-else>If you need to get more credits, please contact our Customer Success Team by clicking the button below</span>
      </p>
      <b-button
        v-if="!isSuccess && !isRequestSaved"
        variant="light"
        class="get-credits-btn"
        :disabled="isLoading"
        @click="handleGetCredits"
      >
        <b-spinner
          v-if="isLoading"
          small
        />
        <span v-else>
          Get credits
        </span>
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import store from '@/store'
import { BModal, BSpinner, BButton } from 'bootstrap-vue'
import CloseIcon from '@/@core/assets/svg-components/CloseIcon.vue'

export default {
  name: 'GetCreditsModal',
  components: {
    BModal,
    BSpinner,
    BButton,
    CloseIcon,
  },
  props: {
    isRequestSaved: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      availableCredits: store.state.leadai.availableCredits,
    }
  },
  methods: {
    handleGetCredits() {
      this.isSuccess = true
      this.$emit('on-request-success')
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_GET_CREDITS_BUTTON_IN_MODAL, {
        availableCredits: this.availableCredits,
      })
    },
    handleHide() {
      this.isSuccess = false
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLOSES_GET_CREDITS_MODAL, {
        availableCredits: this.availableCredits,
      })
    },
  },
}
</script>
<style lang="scss">
.modal-wrapper {
  padding: 25px 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pink-text {
  text-align: center;
  color: #8D82FD;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

.get-credits-btn {
  background-color: #8D82FD !important;
  color: #fff !important;
  border: none;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  width: 145px;
  cursor: pointer;
}

#get-credits-modal {
  .modal-dialog {
    .modal-content {
      width: 345px;
      height: 186px;
    }
    .modal-header {
      background: none;
      padding-top: 0;
      padding-bottom: 0;

      .close {
        color: #5E5873 !important
      }
    }

    .modal-body {
      padding: 0;
    }
  }
}

</style>
