import { render, staticRenderFns } from "./LeadsAiHorizontalCard.vue?vue&type=template&id=27c138a9&scoped=true&"
import script from "./LeadsAiHorizontalCard.vue?vue&type=script&lang=js&"
export * from "./LeadsAiHorizontalCard.vue?vue&type=script&lang=js&"
import style0 from "./LeadsAiHorizontalCard.vue?vue&type=style&index=0&id=27c138a9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27c138a9",
  null
  
)

export default component.exports