<template>
  <b-modal
    :id="`no-contacts-modal-${leadDomain}`"
    dialog-class="no-contacts-modal"
    hide-footer
    hide-header-close
    centered
    @hide="handleModalClose"
  >
    <div class="no-contacts-modal-container">
      <button
        class="close-btn"
        @click="$bvModal.hide(`no-contacts-modal-${leadDomain}`)"
      >
        <close-icon />
      </button>
      <h4 class="title">
        No contacts available for this lead
      </h4>
      <p class="text">
        This lead currently has no contacts. You will not be charged for saving it. <br> <br> Would you still like to save this lead?
      </p>
      <div class="btn-row">
        <b-button
          variant="light"
          class="btn no-save"
          @click="$bvModal.hide(`no-contacts-modal-${leadDomain}`)"
        >
          Don't save
        </b-button>
        <b-button
          variant="light"
          class="btn"
          @click="$emit('on-save-lead-click')"
        >
          Save Lead
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { BButton, BModal } from 'bootstrap-vue'
import CloseIcon from '@/@core/assets/svg-components/CloseIcon.vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'

export default {
  name: 'NoContactsModal',
  components: {
    BModal,
    CloseIcon,
    BButton,
  },
  props: {
    leadName: {
      type: String,
      default: '',
    },
    leadDomain: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleModalClose() {
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLOSES_NO_CONTACTS_MODAL, {
        leadName: this.leadName,
        leadDomain: this.leadDomain,
      })
    },
  },
}
</script>
<style lang="scss">
.no-contacts-modal-container {
  padding: 32px;
  width: 490px;

  .title {
    color: #8D82FD;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  .text {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    width: 370px;
    margin: 0 auto;
  }

  .btn-row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 30px;

    .btn {
      background-color: #7163FF;
      color: #fff;
      border: 1px solid #7163FF;
      font-weight: 400;
      width: 145px;
    }

    .no-save {
      background-color: #fff !important;
      color: #7163FF !important;
    }
  }
}

.no-contacts-modal {
  .modal-header {
    background: none;
    padding-top: 0;
    padding-bottom: 0;

    .close {
      color: #5E5873 !important
    }
  }

  .modal-body {
    padding: 0;
  }
}
</style>
