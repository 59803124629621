<template>
  <div>
    <div
      v-if="!contactString"
      class="d-flex align-items-start justify-content-center"
    >
      <b-link
        v-if="linkedinUrl"
        target="_blank"
        :href="linkedinUrl"
      >
        LinkedIn profile
      </b-link>
      <span v-else>N/A</span>
      <info-icon
        :id="`profile-info-phoneNumber-${randomId}`"
        class="info-icon"
        title="Tooltip"
      />
      <b-tooltip
        custom-class="profile-info-tooltip"
        :target="`profile-info-phoneNumber-${randomId}`"
        triggers="hover"
        boundary="window"
        placement="rightbottom"
      >
        You were not charged for this contact
      </b-tooltip>
    </div>
    <b-button
      v-else-if="contactString === 'REVEAL_REQUIRED'"
      :class="{'reveal-contact-btn': true, 'reduce-padding': !isRevealing, 'd-flex justify-content-center align-items-center': isRevealing}"
      @click="handleRevealContact(false)"
      @disabled="isRevealing"
    >
      <b-spinner
        v-if="isRevealing"
        class="mr-1"
        small
        label="Loading..."
      />
      <span v-else>Request {{ contactType }}
        <span class="credit-block">{{ creditRates.reveal }} <coin-icon /></span>
      </span>
    </b-button>
    <div
      v-else-if="contactString === 'EXTERNAL_REVEAL_REQUIRED' || contactString === 'REVEAL_REQUESTED'"
      class="request-wrapper"
    >
      <span>Contact Requested</span>
      <b-spinner
        class="mr-1"
        small
        label="Loading..."
      />
    </div>
    <b-link
      v-else
      :href="contactType === 'phone' ? `tel:${contactString}` : `mailto:${contactString}`"
      @click="trackContactClick"
    >
      {{ contactString }}
    </b-link>
  </div>
</template>
<script>
import {
 BLink, BTooltip, BButton, BSpinner,
} from 'bootstrap-vue'
import InfoIcon from '@/@core/assets/svg-components/InfoIcon.vue'
import store from '@/store'
import CoinIcon from '@/@core/assets/svg-components/CoinIcon.vue'
import { FETCH_AVAILABLE_CREDITS, REVEAL_ONE_CONTACT, SET_AVAILABLE_CREDITS } from '@/store/modules/leadai.module'
import { apiToastError } from '@/@core/utils/toast'
import { validatorUrl } from '@/@core/utils/validations/validators'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'

export default {
  name: 'RenderLeadContact',
  components: {
    BLink,
    BTooltip,
    BButton,
    InfoIcon,
    BSpinner,
    CoinIcon,
  },
  props: {
    contact: {
      type: String,
      nullable: true,
      required: true,
    },
    contactId: {
      type: String,
      required: true,
    },
    leadId: {
      type: String,
      required: true,
    },
    contactType: {
      type: String,
      default: 'email',
    },
    linkedinProfile: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      contactString: this.contact || '',
      linkedinUrl: this.linkedinProfile,
      isRevealing: false,
      isRefreshing: false,
      randomId: Math.random().toString(),
    }
  },
  computed: {
    creditRates() {
      return store.state.leadai.currentCreditRates
    },
  },
  watch: {
    contact(val) {
      this.contactString = val
    },
  },
  methods: {
    async fetchAvailableCredits() {
      const availableCreditsResponse = await store.dispatch(FETCH_AVAILABLE_CREDITS, {
        brandId: store.getters.currentEntityId,
      })

      if (availableCreditsResponse?.data) {
        store.commit(SET_AVAILABLE_CREDITS, availableCreditsResponse.data?.availableCredits)
      }
    },
    handleRevealContactSuccess(data) {
      if (!data?.contact) {
        apiToastError('Contact was not revealed. Your credits have been refunded.')
      }
      if (validatorUrl(data?.contact)) {
        this.linkedinUrl = data?.linkedinUrl ?? this.linkedinUrl
        this.contactString = null
      } else {
        this.contactString = data?.contact
      }
      this.$emit('reveal-contact-success', data?.contact)
      this.fetchAvailableCredits()
    },
    trackContactClick() {
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICKS_REVEALED_CONTACT, {
        contactId: this.contactId,
        type: this.contactType,
      })
    },
    async handleRevealContact(isRefresh = false) {
      if (!isRefresh) {
        this.$emit('reveal-contact-click')
      } else {
        this.$emit('refresh-contact-click')
      }
      try {
        this.isRevealing = true
        this.isRefreshing = isRefresh
        const response = await store.dispatch(REVEAL_ONE_CONTACT, {
          id: this.leadId,
          payload: {
            type: this.contactType,
            contactId: this.contactId,
          },
        })

        if (response?.data) {
          this.handleRevealContactSuccess(response?.data)
        }
      } catch (err) {
        apiToastError(err)
        this.$emit('reveal-contact-failed', err)
      } finally {
        this.isRevealing = false
        setTimeout(() => {
          this.isRefreshing = false
        }, 500)
      }
    },
  },
}
</script>
<style>
.request-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.spin {
  width: 16px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
</style>
