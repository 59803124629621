<template>
  <svg
    width="13"
    height="11"
    viewBox="0 0 13 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0376 1.98698C9.81788 1.28464 8.20643 0.897827 6.50001 0.897827C4.7936 0.897827 3.18214 1.28464 1.96247 1.98698C0.696947 2.71571 0 3.6967 0 4.74922V6.27994C0 7.33128 0.697376 8.30733 1.96365 9.02827C3.17992 9.72076 4.79097 10.1022 6.50001 10.1022C8.20906 10.1022 9.82011 9.72076 11.0364 9.02827C12.3027 8.3073 13 7.33125 13 6.27994V4.74922C13 3.6967 12.3031 2.71571 11.0376 1.98698ZM2.24372 2.47541C3.3792 1.82157 4.89076 1.46146 6.49999 1.46146C8.10922 1.46146 9.6208 1.82154 10.7563 2.47541C11.8397 3.0993 12.4363 3.9068 12.4363 4.74922C12.4363 5.58683 11.8405 6.38351 10.7586 6.99248C9.62888 7.62839 8.11646 7.97863 6.49996 7.97863C4.88346 7.97863 3.37109 7.62842 2.24133 6.99248C1.15945 6.38351 0.563634 5.58686 0.563634 4.74922C0.563634 3.90683 1.16028 3.0993 2.24372 2.47541ZM6.2182 8.53866V9.53476C5.54975 9.51751 4.90182 9.43887 4.29457 9.30375V8.32346C4.90595 8.44942 5.55303 8.52253 6.2182 8.53866ZM6.78183 8.53866C7.447 8.52253 8.09408 8.44939 8.70546 8.32346V9.30375C8.09821 9.43887 7.45027 9.51751 6.78183 9.53476V8.53866ZM0.56452 6.31969C0.748291 6.56009 0.975543 6.78853 1.24364 7.00187V7.78267C0.810667 7.33283 0.576034 6.83383 0.56452 6.31969ZM1.80725 8.26203V7.39145C1.85889 7.42259 1.91118 7.45343 1.96485 7.48365C2.48753 7.77784 3.0842 8.01491 3.73091 8.18967V9.15858C3.18783 8.99883 2.68601 8.79098 2.24251 8.5385C2.08722 8.45006 1.94226 8.35768 1.80725 8.26203ZM10.7575 8.53847C10.314 8.79098 9.81217 8.99883 9.26909 9.15855V8.18964C9.9158 8.01491 10.5125 7.77784 11.0351 7.48362C11.0888 7.4534 11.1411 7.42256 11.1927 7.39143V8.262C11.0578 8.35768 10.9128 8.45006 10.7575 8.53847ZM11.7564 7.78269V7.0019C12.0245 6.78855 12.2517 6.56012 12.4355 6.31972C12.424 6.83383 12.1893 7.33283 11.7564 7.78269Z"
      fill="#7163FF"
    />
  </svg>
</template>
<script>
export default {
  name: 'CoinIcon',
}
</script>
