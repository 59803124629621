<template>
  <div class="filter-card">
    <!-- Search Input -->
    <div
      v-if="allowSearch"
      class="search-bar"
    >
      <span
        class="reset-filters"
        @click="resetFilters"
      > Reset Filters </span>
      <b-form-input
        :value="searchText"
        placeholder="Search by lead’s name"
        @input="searchLeads"
      />
    </div>

    <!-- Filter Options -->
    <div v-if="allowFilterOptions">
      <div
        v-if="loading"
        class="spinner-container"
      >
        <b-spinner label="Loading..." />
      </div>
      <div v-else>
        <!-- Country Dropdown -->
        <div
          v-if="localFilterOptions.countries.length"
          class="filter-group"
        >
          <h4>Country</h4>
          <custom-select
            id="leads-filter-countries-dropdown"
            v-model="selectedCountry"
            :option-groups="filteredCountryOptionData"
            placeholder="Type country name"
            heading="CountryHeading"
            label="CountryLabel"
            :display-prefix-icon="true"
            :clear-search-on-select="true"
            :close-on-select="true"
            theme="grey"
            no-data-text="No country found."
            @search="handleFilterOptions"
            @select="selectCountry"
          />
        </div>

        <!-- Store Type Filters -->
        <div
          v-if="localFilterOptions.storeTypes.length"
          class="filter-group"
        >
          <h4>Store Type</h4>
          <div class="checkbox-list">
            <label
              v-for="store in localFilterOptions.storeTypes"
              :key="store"
              class="checkbox-item"
            >
              <input
                type="checkbox"
                :value="store"
                :checked="selectedStoreTypes.includes(store)"
                name="selectedStoreTypes"
                @change="updateFilterValue"
              >
              <span>{{ store }}</span>
            </label>
          </div>
        </div>

        <!-- Categories Filters -->
        <div
          v-if="localFilterOptions.categories.length"
          class="filter-group"
        >
          <h4>Categories</h4>
          <div class="checkbox-list">
            <label
              v-for="category in localFilterOptions.categories"
              :key="category"
              class="checkbox-item"
            >
              <input
                type="checkbox"
                :checked="selectedCategories.includes(category)"
                :value="category"
                name="selectedCategories"
                @change="updateFilterValue"
              >
              <span>{{ category }}</span>
            </label>
          </div>
        </div>

        <!-- Average Retail Price Filters -->
        <div
          v-if="localFilterOptions.avgRetailPrice.length"
          class="filter-group"
        >
          <h4>Average retail price</h4>
          <div class="checkbox-list">
            <label
              v-for="price in localFilterOptions.avgRetailPrice"
              :key="price"
              class="checkbox-item"
            >
              <input
                name="selectedRetailPrices"
                type="checkbox"
                :checked="selectedRetailPrices.includes(price)"
                :value="price"
                @change="updateFilterValue"
              >
              <span>{{ price }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BSpinner,
} from 'bootstrap-vue'
import { debounce } from '@core/utils/utils'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import {
 FETCH_LEAD_FILTERS, RESET_FILTERS, UPDATE_SELECTED_COUNTRY,
} from '@/store/modules/leadai.module'
import { apiToastError } from '@/@core/utils/toast'
import store from '@/store'
import { union } from 'lodash'
import CustomSelect from '@/@core/components/custom-select/CustomSelect.vue'
import { GET_COUNTRIES } from '@/store/modules/common.module'

export default {
  components: {
    BFormInput,
    BSpinner,
    CustomSelect,
  },
  props: {
    allowSearch: {
      type: Boolean,
      default: true,
    },
    allowFilterOptions: {
      type: Boolean,
      default: true,
    },
    searchText: {
      type: String,
      default: '',
    },
    selectedCountry: {
      type: Array,
      default: () => [],
    },
    selectedStoreTypes: {
      type: Array,
      default: () => [],
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    },
    selectedRetailPrices: {
      type: Array,
      default: () => [],
    },
    filterOptions: {
      type: Object,
      default: () => ({
        countries: [],
        storeTypes: [],
        categories: [],
        avgRetailPrice: [],
      }),
    },
  },
  emits: ['searchData', 'filterChanged', 'resetFilters'],
  data() {
    return {
      country: null,
      searchTemp: '',
      countrySearchText: '',
      loading: false,
      debouncedSearchLeads: null,
      localFilterOptions: { ...this.filterOptions },
      allCountries: [],
    }
  },
  computed: {
    filteredCountryOptionData() {
      return [{
        title: '',
        options: [...this.allCountries.filter(c => c.toLowerCase()
        .indexOf(this.countrySearchText.toLowerCase()) !== -1)
        .sort()],
      }]
    },
    hasSelectedFilters() {
      return (
        this.selectedCountry.length > 0
        || this.selectedStoreTypes.length > 0
        || this.selectedCategories.length > 0
        || this.selectedRetailPrices.length > 0
      )
    },
  },
  watch: {
    selectedCountry(val) {
      store.commit(UPDATE_SELECTED_COUNTRY, val)
      this.applyFilters()
    },
  },
  created() {
    this.searchTemp = this.searchText
    this.getFilterOptions()
    this.debouncedSearchLeads = debounce(() => {
      analytics.track(constants.TRACKS.ACTIONS.LEAD_AI.BRAND_SEARCH_LEADS, {
        search_keyword: this.searchText,
      })
      this.getFilterOptions()
      this.$emit('searchData', this.searchText)
    }, 500)
  },
  methods: {
    applyFilters() {
      this.$emit('filterChanged')
      analytics.track(
        constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICK_LEADAI_FILTERS,
        {
          selected_store_types: this.selectedStoreTypes,
          selected_categories: this.selectedCategories,
          selected_retail_prices: this.selectedRetailPrices,
        },
      )
    },
    getAllCountries() {
      store.dispatch(GET_COUNTRIES).then(res => {
        this.allCountries = res.data.data.COUNTRIES
        this.isCommonDataLoaded = true
      })
    },
    handleFilterOptions(value) {
      this.countrySearchText = value
    },
    updateFilterValue(event) {
      let newValue = []
      switch (event.target.name) {
        case 'selectedStoreTypes':
          newValue = [...this.selectedStoreTypes]
          break
        case 'selectedCategories':
          newValue = [...this.selectedCategories]
          break
        case 'selectedRetailPrices':
          newValue = [...this.selectedRetailPrices]
          break
        default:
          break
      }
      if (event.target.checked) {
        newValue.push(event.target.value)
      } else {
        const index = newValue.indexOf(event.target.value)
        if (index > -1) newValue.splice(index, 1)
      }
      this.$emit(`update:${event.target.name}`, newValue)
      this.applyFilters()
    },
    getSearchPayload(shouldOmitSearch) {
      let search = ''
      if (!shouldOmitSearch) {
        search = this.searchTemp
      }

      return search
    },
    getUpdatedFilters(newFilters) {
      const isNoFiltersFound = Object.values(newFilters).every(
            obj => obj !== null
              && typeof obj === 'object'
              && Object.keys(obj).length === 0,
          )
      if (isNoFiltersFound) {
        return this.localFilterOptions
      }
      return {
        countries: union(this.selectedCountry, newFilters.countries),
        categories: union(this.selectedCategories, newFilters.categories),
        storeTypes: union(this.selectedStoreTypes, newFilters.storeTypes),
        avgRetailPrice: union(this.selectedRetailPrices, newFilters.avgRetailPrice),
      }
    },
    getFilterOptions(options) {
      this.getAllCountries()
      const search = this.getSearchPayload(Boolean(options?.shouldOmitSearch))
      this.$store
        .dispatch(FETCH_LEAD_FILTERS, { search })
        .then(response => {
          this.localFilterOptions = this.getUpdatedFilters(response?.data?.filters)
        })
        .catch(err => {
          apiToastError(err)
        })
    },
    searchLeads(value) {
      this.$emit('update:searchText', value)
      if (value.trim() === this.searchTemp.trim()) {
        return
      }
      if (value.trim() === '' && value !== '') {
        return
      }
      this.searchTemp = value
      this.debouncedSearchLeads()
    },
    resetFilters() {
      this.searchTemp = ''
      store.commit(RESET_FILTERS)
      this.$emit('resetFilters')
      this.getFilterOptions({
        shouldOmitSearch: true,
      })

      analytics.track(
        constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICK_RESET_LEADAI_FILTERS,
      )
    },
    selectCountry(countries) {
      store.commit(UPDATE_SELECTED_COUNTRY, countries)
      analytics.track(
        constants.TRACKS.ACTIONS.LEAD_AI.BRAND_CLICK_COUNTRY_LEADAI_FILTER,
        {
          selected_countries: countries,
        },
      )
    },
  },
}
</script>
<style scoped lang="scss">
.filter-card {
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-bar {
  margin-bottom: 15px;
}

.search-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.filter-group {
  margin-bottom: 20px;
}

h4 {
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: 600;
}

.dropdown {
  width: 100%;
  font-size: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  height: 40px;
}

#dropdown-country {
  .toggle-button {
    max-width: 100%;
    line-height: normal;
  }
}

.moveUp {
  position: relative;
  top: -3px;
}

.fixed-width {
  max-width: calc(100% - 24px);
}

.checkbox-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkbox-item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

input[type='checkbox'] {
  cursor: pointer;
  accent-color: black;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
  font-size: 14px;
  margin: 0;
  /* Ensure no default margin */
  padding: 0;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.selected-option {
  background-color: #EBEAE8 !important;
}

.b-dropdown .dropdown-menu-scrollable {
  max-height: 200px;
  overflow-y: auto;
}

.custom-dropdown-item {
  background-color: transparent;
  color: #333;
}

.custom-dropdown-item:hover {
  background-color: #f0f0f0;
}

.select-country-item {
  background-color: rgba(136, 133, 133, 0.176) !important;
  color: white !important;
}

.dropdown-toggle {
  width: 6px;
  height: 6px;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.reset-filters {
  margin: 8px 0;
  display: block;
  color: #007bff;
  cursor: pointer;
  transition: 0.3s ease all;
}

.reset-filters:hover {
  color: #0056b3;
}
</style>
