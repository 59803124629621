<template>
  <div class="expandable-wrapper">
    <p
      ref="expandableText"
      class="expandable-text"
      :class="!isExpanded ? 'expand-ellipsis' : ''"
    >
      <span class="pink-text">{{ title }}: </span> {{ description }}
    </p>
    <p
      v-if="isToggleVisible"
      class="expand-view-toggle"
      @click="handleExpandToggle"
    >
      View
      <span v-if="isExpanded">less
        <feather-icon
          icon="ChevronUpIcon"
          size="14"
        />
      </span>
      <span v-else>more
        <feather-icon
          icon="ChevronDownIcon"
          size="20"
        />
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: 'ExpandableText',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isExpanded: false,
      isToggleVisible: false,
    }
  },
  mounted() {
    const el = this.$refs.expandableText
    if (el.scrollHeight > el.clientHeight) {
      this.isToggleVisible = true
    }
  },
  methods: {
    handleExpandToggle() {
      this.isExpanded = !this.isExpanded
    },
  },
}
</script>
<style lang="scss" scoped>
.expandable-wrapper {
  margin-bottom: 17px;
  width: 100%;
}
.expandable-text {
  font-size: 16px;
  line-height: 24px;
  max-height: 168px;
  overflow-y: scroll;
  margin-bottom: 0;
  .pink-text {
    color: #8D82FD;
    font-weight: 700;
  }
  max-width: 100%;
}
.expand-view-toggle {
  cursor: pointer;
  font-weight: 700;
  line-height: 24px;
  color: #686767;
}
.expand-ellipsis {
  max-width: calc(100% - 1px);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
</style>
