export const tabData = [
  {
    id: 'leads-ai',
    name: 'Leads AI',
    default: true,
  },
  {
    id: 'saved-leads',
    name: 'Saved Leads',
  },
  {
    id: 'leads-database',
    name: 'Leads Database',
  },
]

export const ContactStatus = {
  REVEAL_REQUIRED: 'REVEAL_REQUIRED',
  EXTERNAL_REVEAL_REQUIRED: 'EXTERNAL_REVEAL_REQUIRED',
  REVEAL_REQUESTED: 'REVEAL_REQUESTED',
}
