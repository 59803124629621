<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
  >
    <rect
      x="0.5"
      y="0.5"
      width="10"
      height="10"
      rx="5"
      stroke="#240FFC"
    />
    <path
      d="M4.98 3.768V3.105H5.703V3.768H4.98ZM4.98 7.5V4.26H5.703V7.5H4.98Z"
      fill="#240FFC"
    />
  </svg>
</template>
<script>
export default {
  name: 'InfoIcon',
}
</script>
