<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="27"
    viewBox="0 0 23 27"
    fill="none"
  >
    <path
      d="M19.1789 5.48575C17.1148 4.23853 14.3878 3.55162 11.5 3.55162C8.61223 3.55162 5.88515 4.23853 3.82109 5.48575C1.67945 6.77983 0.5 8.52188 0.5 10.391V13.1092C0.5 14.9762 1.68017 16.7095 3.82309 17.9897C5.88138 19.2195 8.60778 19.8968 11.5 19.8968C14.3922 19.8968 17.1186 19.2195 19.1769 17.9897C21.3198 16.7094 22.5 14.9762 22.5 13.1092V10.391C22.5 8.52188 21.3206 6.77983 19.1789 5.48575ZM4.29706 6.35311C6.21863 5.19201 8.77665 4.55253 11.5 4.55253C14.2233 4.55253 16.7813 5.19196 18.7029 6.35311C20.5364 7.46102 21.5461 8.89498 21.5461 10.391C21.5461 11.8784 20.5378 13.2932 18.7069 14.3746C16.795 15.5038 14.2355 16.1258 11.4999 16.1258C8.7643 16.1258 6.20492 15.5039 4.29301 14.3746C2.46214 13.2932 1.45384 11.8784 1.45384 10.391C1.45384 8.89503 2.46355 7.46102 4.29706 6.35311ZM11.0231 17.1203V18.8892C9.89187 18.8585 8.79536 18.7189 7.76771 18.479V16.7381C8.80236 16.9618 9.89741 17.0916 11.0231 17.1203ZM11.9769 17.1203C13.1026 17.0916 14.1976 16.9618 15.2323 16.7381V18.479C14.2046 18.7189 13.1081 18.8585 11.9769 18.8892V17.1203ZM1.45534 13.1798C1.76634 13.6067 2.15092 14.0124 2.60462 14.3912V15.7778C1.87189 14.979 1.47482 14.0928 1.45534 13.1798ZM3.55842 16.629V15.0831C3.64581 15.1384 3.73429 15.1931 3.82513 15.2468C4.70966 15.7692 5.71941 16.1902 6.81383 16.5005V18.2211C5.89478 17.9375 5.04555 17.5684 4.29501 17.12C4.03221 16.963 3.78689 16.7989 3.55842 16.629ZM18.7049 17.12C17.9545 17.5684 17.1052 17.9375 16.1861 18.2211V16.5005C17.2805 16.1902 18.2903 15.7692 19.1748 15.2467C19.2657 15.1931 19.3541 15.1383 19.4415 15.083V16.629C19.2131 16.7989 18.9678 16.963 18.7049 17.12ZM20.3954 15.7778V14.3913C20.849 14.0124 21.2337 13.6068 21.5447 13.1799C21.5251 14.0928 21.1281 14.979 20.3954 15.7778Z"
      :fill="color"
    />
    <path
      d="M19.1789 5.48575C17.1148 4.23853 14.3878 3.55162 11.5 3.55162C8.61223 3.55162 5.88515 4.23853 3.82109 5.48575C1.67945 6.77983 0.5 8.52188 0.5 10.391V13.1092C0.5 14.9762 1.68017 16.7095 3.82309 17.9897C5.88138 19.2195 8.60778 19.8968 11.5 19.8968C14.3922 19.8968 17.1186 19.2195 19.1769 17.9897C21.3198 16.7094 22.5 14.9762 22.5 13.1092V10.391C22.5 8.52188 21.3206 6.77983 19.1789 5.48575ZM4.29706 6.35311C6.21863 5.19201 8.77665 4.55253 11.5 4.55253C14.2233 4.55253 16.7813 5.19196 18.7029 6.35311C20.5364 7.46102 21.5461 8.89498 21.5461 10.391C21.5461 11.8784 20.5378 13.2932 18.7069 14.3746C16.795 15.5038 14.2355 16.1258 11.4999 16.1258C8.7643 16.1258 6.20492 15.5039 4.29301 14.3746C2.46214 13.2932 1.45384 11.8784 1.45384 10.391C1.45384 8.89503 2.46355 7.46102 4.29706 6.35311ZM11.0231 17.1203V18.8892C9.89187 18.8585 8.79536 18.7189 7.76771 18.479V16.7381C8.80236 16.9618 9.89741 17.0916 11.0231 17.1203ZM11.9769 17.1203C13.1026 17.0916 14.1976 16.9618 15.2323 16.7381V18.479C14.2046 18.7189 13.1081 18.8585 11.9769 18.8892V17.1203ZM1.45534 13.1798C1.76634 13.6067 2.15092 14.0124 2.60462 14.3912V15.7778C1.87189 14.979 1.47482 14.0928 1.45534 13.1798ZM3.55842 16.629V15.0831C3.64581 15.1384 3.73429 15.1931 3.82513 15.2468C4.70966 15.7692 5.71941 16.1902 6.81383 16.5005V18.2211C5.89478 17.9375 5.04555 17.5684 4.29501 17.12C4.03221 16.963 3.78689 16.7989 3.55842 16.629ZM18.7049 17.12C17.9545 17.5684 17.1052 17.9375 16.1861 18.2211V16.5005C17.2805 16.1902 18.2903 15.7692 19.1748 15.2467C19.2657 15.1931 19.3541 15.1383 19.4415 15.083V16.629C19.2131 16.7989 18.9678 16.963 18.7049 17.12ZM20.3954 15.7778V14.3913C20.849 14.0124 21.2337 13.6068 21.5447 13.1799C21.5251 14.0928 21.1281 14.979 20.3954 15.7778Z"
      :fill="color"
    />
    <path
      d="M19.1789 9.03737C17.1148 7.79015 14.3878 7.10324 11.5 7.10324C8.61223 7.10324 5.88515 7.79015 3.82109 9.03737C1.67945 10.3315 0.5 12.0735 0.5 13.9426V16.6609C0.5 18.5278 1.68017 20.2611 3.82309 21.5414C5.88138 22.7711 8.60778 23.4484 11.5 23.4484C14.3922 23.4484 17.1186 22.7711 19.1769 21.5414C21.3198 20.2611 22.5 18.5278 22.5 16.6609V13.9426C22.5 12.0735 21.3206 10.3315 19.1789 9.03737ZM4.29706 9.90473C6.21863 8.74363 8.77665 8.10415 11.5 8.10415C14.2233 8.10415 16.7813 8.74358 18.7029 9.90473C20.5364 11.0126 21.5461 12.4466 21.5461 13.9426C21.5461 15.43 20.5378 16.8448 18.7069 17.9262C16.795 19.0554 14.2355 19.6774 11.4999 19.6774C8.7643 19.6774 6.20492 19.0555 4.29301 17.9262C2.46214 16.8448 1.45384 15.4301 1.45384 13.9426C1.45384 12.4467 2.46355 11.0126 4.29706 9.90473ZM11.0231 20.6719V22.4408C9.89187 22.4102 8.79536 22.2705 7.76771 22.0306V20.2898C8.80236 20.5134 9.89741 20.6433 11.0231 20.6719ZM11.9769 20.6719C13.1026 20.6433 14.1976 20.5134 15.2323 20.2898V22.0306C14.2046 22.2705 13.1081 22.4102 11.9769 22.4408V20.6719ZM1.45534 16.7314C1.76634 17.1583 2.15092 17.564 2.60462 17.9429V19.3294C1.87189 18.5306 1.47482 17.6445 1.45534 16.7314ZM3.55842 20.1807V18.6347C3.64581 18.69 3.73429 18.7447 3.82513 18.7984C4.70966 19.3208 5.71941 19.7418 6.81383 20.0522V21.7728C5.89478 21.4891 5.04555 21.12 4.29501 20.6716C4.03221 20.5146 3.78689 20.3505 3.55842 20.1807ZM18.7049 20.6716C17.9545 21.12 17.1052 21.4891 16.1861 21.7727V20.0521C17.2805 19.7418 18.2903 19.3208 19.1748 18.7984C19.2657 18.7447 19.3541 18.6899 19.4415 18.6346V20.1806C19.2131 20.3505 18.9678 20.5146 18.7049 20.6716ZM20.3954 19.3295V17.9429C20.849 17.564 21.2337 17.1584 21.5447 16.7315C21.5251 17.6445 21.1281 18.5306 20.3954 19.3295Z"
      :fill="color"
    />
    <path
      d="M19.1789 12.589C17.1148 11.3418 14.3878 10.6549 11.5 10.6549C8.61223 10.6549 5.88515 11.3418 3.82109 12.589C1.67945 13.8831 0.5 15.6251 0.5 17.4942V20.2125C0.5 22.0794 1.68017 23.8127 3.82309 25.093C5.88138 26.3227 8.60778 27 11.5 27C14.3922 27 17.1186 26.3227 19.1769 25.093C21.3198 23.8127 22.5 22.0794 22.5 20.2125V17.4942C22.5 15.6251 21.3206 13.8831 19.1789 12.589ZM4.29706 13.4564C6.21863 12.2953 8.77665 11.6558 11.5 11.6558C14.2233 11.6558 16.7813 12.2952 18.7029 13.4564C20.5364 14.5643 21.5461 15.9982 21.5461 17.4942C21.5461 18.9816 20.5378 20.3964 18.7069 21.4778C16.795 22.6071 14.2355 23.229 11.4999 23.229C8.7643 23.229 6.20492 22.6071 4.29301 21.4778C2.46214 20.3964 1.45384 18.9817 1.45384 17.4942C1.45384 15.9983 2.46355 14.5643 4.29706 13.4564ZM11.0231 24.2235V25.9924C9.89187 25.9618 8.79536 25.8221 7.76771 25.5822V23.8414C8.80236 24.0651 9.89741 24.1949 11.0231 24.2235ZM11.9769 24.2235C13.1026 24.1949 14.1976 24.065 15.2323 23.8414V25.5822C14.2046 25.8221 13.1081 25.9618 11.9769 25.9924V24.2235ZM1.45534 20.2831C1.76634 20.71 2.15092 21.1156 2.60462 21.4945V22.881C1.87189 22.0822 1.47482 21.1961 1.45534 20.2831ZM3.55842 23.7323V22.1863C3.64581 22.2416 3.73429 22.2964 3.82513 22.35C4.70966 22.8725 5.71941 23.2935 6.81383 23.6038V25.3244C5.89478 25.0407 5.04555 24.6716 4.29501 24.2232C4.03221 24.0662 3.78689 23.9021 3.55842 23.7323ZM18.7049 24.2232C17.9545 24.6716 17.1052 25.0407 16.1861 25.3243V23.6037C17.2805 23.2935 18.2903 22.8725 19.1748 22.35C19.2657 22.2963 19.3541 22.2415 19.4415 22.1863V23.7322C19.2131 23.9021 18.9678 24.0662 18.7049 24.2232ZM20.3954 22.8811V21.4945C20.849 21.1157 21.2337 20.71 21.5447 20.2831C21.5251 21.1961 21.1281 22.0822 20.3954 22.8811Z"
      :fill="color"
    />
    <path
      d="M19.1789 1.93416C17.1148 0.686939 14.3877 3.04838e-05 11.5 3.04838e-05C8.61218 3.04838e-05 5.88511 0.686939 3.82104 1.93416C1.6794 3.22824 0.499953 4.97029 0.499953 6.83937V9.55764C0.499953 11.4246 1.68013 13.1579 3.82304 14.4382C5.88134 15.6679 8.60773 16.3452 11.5 16.3452C14.3922 16.3452 17.1186 15.6679 19.1769 14.4382C21.3198 13.1579 22.5 11.4246 22.5 9.55764V6.83937C22.4999 4.97029 21.3205 3.22824 19.1789 1.93416ZM4.29701 2.80152C6.21859 1.64042 8.77665 13.0774 11.5 13.0774C14.2233 13.0774 16.3476 9.49374 18.2691 10.6549C20.1027 11.7628 20.8076 3.30643 20.8076 4.8024C20.8076 6.28985 6.12788 1.72011 4.29701 2.80152C2.3851 3.93078 14.2356 13.0774 11.5 13.0774C8.76434 13.0774 21.0273 8.59543 19.1153 7.46612C17.2845 6.38471 20.8076 6.28989 20.8076 4.8024C20.8076 3.30648 2.4635 3.90943 4.29701 2.80152ZM11.023 13.5687V15.3376C9.89182 15.3069 8.79532 15.1673 7.76767 14.9274V13.1865C8.80231 13.4102 9.89737 13.5401 11.023 13.5687ZM11.9769 13.5687C13.1025 13.5401 14.1976 13.4102 15.2322 13.1865V14.9274C14.2046 15.1673 13.1081 15.3069 11.9769 15.3376V13.5687ZM1.45529 9.62823C1.76629 10.0551 2.15087 10.4608 2.60458 10.8397V12.2262C1.87185 11.4274 1.47478 10.5412 1.45529 9.62823ZM3.55837 13.0774V11.5315C3.64576 11.5868 3.73424 11.6415 3.82508 11.6952C4.70961 12.2176 5.71937 12.6386 6.81378 12.9489V14.6696C5.89474 14.3859 5.0455 14.0168 4.29496 13.5684C4.03216 13.4114 3.78684 13.2473 3.55837 13.0774ZM18.7049 13.5684C17.9544 14.0168 17.1051 14.3859 16.1861 14.6695V12.9489C17.2805 12.6386 18.2903 12.2176 19.1748 11.6951C19.2656 11.6415 19.3541 11.5867 19.4415 11.5314V13.0774C19.2131 13.2473 18.9677 13.4114 18.7049 13.5684ZM20.3953 12.2262V10.8397C20.849 10.4608 21.2336 10.0552 21.5446 9.62828C21.5251 10.5412 21.128 11.4274 20.3953 12.2262Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: 'CreditIcon',
  props: {
    color: {
      type: String,
      default: '#7163FF',
    },
  },
}
</script>
