<template>
  <b-modal
    :id="`choose-email-modal-${leadId}`"
    centered
    hide-header-close
    hide-footer
    dialog-class="choose-email-modal"
    @hide="handleModalClose"
  >
    <div class="choose-email-modal-wrapper">
      <button
        class="close-btn"
        @click="$bvModal.hide(`choose-email-modal-${leadId}`)"
      >
        <close-icon />
      </button>
      <h3 class="title">
        Add new retailer
      </h3>
      <p class="sub-title">
        Select lead’s email to add as a retailer
      </p>
      <custom-select
        id="choose-email-modal-dropdown"
        v-model="email"
        :is-searchable="false"
        :should-scroll-on-focus="false"
        show-arrow-icon
        placeholder="Please select email"
        theme="grey"
        :option-groups="emailOptions"
        :close-on-select="true"
        @select="(newEmail) => email = newEmail"
      />
    </div>
  </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import CloseIcon from '@/@core/assets/svg-components/CloseIcon.vue'
import CustomSelect from '@/@core/components/custom-select/CustomSelect.vue'

export default {
  name: 'ChooseEmailModal',
  components: {
    BModal,
    CustomSelect,
    CloseIcon,
  },
  props: {
    revealedContacts: {
      type: Array,
      default: () => ([]),
    },
    leadId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      email: [],
      emailOptions: [],
    }
  },
  watch: {
    email(val) {
      setTimeout(() => {
        this.$bvModal.hide('choose-email-modal')
        this.$emit('on-choose-email', this.revealedContacts.find(contact => contact.email === val[0]))
      }, 250)
    },
    revealedContacts(val) {
      this.emailOptions = [
        {
          title: '',
          options: val.reduce((acc, current) => [...acc, current.email], []),
        },
      ]
    },
  },
  mounted() {
    this.emailOptions = [
      {
        title: '',
        options: this.revealedContacts.reduce((acc, current) => [...acc, current.email], []),
      },
    ]
  },
  methods: {
    handleModalClose() {
      this.$emit('on-close-choose-email-modal')
      this.email = []
    },
  },
}
</script>
<style lang="scss">
.choose-email-modal-wrapper {
  padding: 42px;
  padding-top: 30px;

  .title {
    font-size: 28px;
    font-weight: 500;
    line-height: normal;
    text-align: center;
  }

  .sub-title {
    line-height: 21px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    text-align: center;
  }
}

.choose-email-modal {
  .modal-header {
    background: none;
    padding-top: 0;
    padding-bottom: 0;

    .close {
      color: #5E5873 !important
    }
  }

  .modal-body {
    padding: 0;
  }
}

</style>
